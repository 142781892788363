<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Products">
      <router-link
        :to="{ name: 'product-create' }"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Product</span>
      </router-link>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex justify-between">
          <div class="flex -mx-2 mr-2">
            <button
              @click="clearSearch"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{ 'bg-orange-300 text-orange-900': currentTab == 'products', 'hover:bg-gray-200': currentTab != 'products' }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                />
                <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12" />
              </svg>
              <span>Products</span>
            </button>
            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{ 'bg-orange-300 text-orange-900': currentTab == 'search', 'hover:bg-gray-200': currentTab != 'search' }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex justify-end flex-1">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search products by any keyword"
              title="Products will be searched by: name, description &amp; notes"
              v-model="searchTerm"
              @keydown.enter="searchProducts"
            />
            <button
              @click="searchProducts"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div class="w-full mt-5">
          <template v-if="currentTab == 'products'">
            <div v-if="viewState == 'Idle'">
              <vue-good-table
                :columns="columns"
                :rows="allProducts"
                :row-style-class="markRowAsSelected"
                :sort-options="{
                  initialSortBy: { field: $store.state.productSortOptions.field, type: $store.state.productSortOptions.type }
                }"
                :pagination-options="paginationOpts"
                v-if="viewState == 'Idle'"
                 styleClass="vgt-table condensed"
                @on-sort-change="productSortChange"
                @on-column-filter="productColumnFilter"
                @on-row-click="onProductRowSelected"
                >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'verified_at'">
                    <span
                      class="flex justify-center"
                      v-html="processVerifiedAt(props.row.verified_at)"
                    ></span>
                  </span>
                  <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
              </vue-good-table>
              <h5
                class="text-muted text-center pt-3 pb-3"
                v-if="allProducts.length == 0"
              >No products have been created yet</h5>
            </div>
          </template>
          <template v-if="currentTab == 'search'">
            <div>
              <div v-if="searchState == 'Idle'" class="mt-5">
                <h4 class="mb-5">{{ searchResults.length }} search results for '{{ searchTermWas }}'</h4>
                <vue-good-table
                  :columns="columns"
                  :rows="searchResults"
                  :row-style-class="markRowAsSelected"
                  v-if="searchResults.length > 0"
                  styleClass="vgt-table condensed"
                  @on-row-click="onProductRowSelected"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'verified_at'">
                      <span
                        class="flex justify-center py-2"
                        v-html="processVerifiedAt(props.row.verified_at)"
                      ></span>
                    </span>
                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                  </template>
                </vue-good-table>
                <h5
                  class="text-center py-3"
                  v-if="!searchResults || searchResults.length == 0"
                >No results for {{ searchTermWas }}</h5>
              </div>
              <div v-if="searchState == 'Searching'" class="flex items-center justify-center py-20">
                <spinner :color="'text-black'" :size="10" />
              </div>
              <div v-if="searchState == 'Error'">
                <div class="alert alert-danger">
                  Something went wrong performing your search, please try again.
                  If the problem persists, please contact your support team.
                </div>
              </div>
            </div>
          </template>
          <div v-if="viewState == 'Loading'" class="flex items-center justify-center py-20">
            <spinner :color="'text-black'" :size="10" />
          </div>
          <div v-if="viewState == 'Error'" class="flex items-center justify-center py-10">
            <div class="bg-red-200 rounded-lg w-full px-4 py-2">
              <h2 class="text-red-600 text-xl font-bold">{{ error.status }}: Error</h2>
              <p class="text-red-800 text-lg">
                There has been an error while trying to loading the products, please try again later.
                <br />If the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>

        <!-- <div v-if="viewState == 'Loading'" class="absolute inset-0 bg-faint-white flex items-center justify-center">
        <spinner :color="'text-black'" :size="10" />
        </div>-->
      </div>
    </main>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "products",
  components: {
    PageHeader,
    Spinner
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      currentTab: "products",
      searchState: "Pending",
      searchTerm: "",
      searchTermWas: "",
      allProducts: [],
      columns: [],
      searchResults: [],
      paginationOpts: {
        enabled: true,
        perPage: 20
      },
      categoryFilter: [],
      typeFilter: [],
      twelveMonthsAgo: this.$moment().subtract(12, "months"),
      eighteenMonthsAgo: this.$moment().subtract(18, "months"),
      twentyFourMonthsAgo: this.$moment().subtract(24, "months")
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      this.allProducts = await this.ProductService.getProducts();

      this.categoryFilter = this.allProducts
        .map(p => {
          return p.category ? p.category : "";
        })
        .filter((val, idx, self) => {
          return self.indexOf(val) === idx && val.length > 0;
        });

      this.typeFilter = this.allProducts
        .map(p => {
          return p.type ? p.type : "";
        })
        .filter((val, idx, self) => {
          return self.indexOf(val) === idx && val.length > 0;
        });

      this.columns = [
        {
          label: "Code",
          field: "product_code",
          width: "200px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Category",
          field: "category",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "All Categories",
          //   filterDropdownItems: this.categoryFilter,
          //   filterValue: this.$store.state.productFilterOptions.category
          // }
        },
        /* {
          label: "Type",
          field: "type",
          width: "150px",
          filterOptions: {
            enabled: true,
            placeholder: "All Types",
            filterDropdownItems: this.typeFilter,
            filterValue: this.$store.state.productFilterOptions.type
          }
        }, */
        {
          label: "Stock Level",
          field: "available_qty",
          type: "number",
          width: "100px"
        },
        {
          field: "verified_at",
          width: "75px"
        }
      ];

      this.searchTerm = this.$store.state.productSearchTerm;
      await this.searchProducts();

      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.error = error;
      this.viewState = "Error";
    }
  },
  methods: {
    onProductRowSelected: function(selectedRow) {
      let productCode = selectedRow.row.product_code.toLowerCase();
      this.$store.dispatch(
        "setLastSelectedProduct",
        selectedRow.row.product_code
      );
      this.$router.push({
        name: "product-edit",
        params: { productCode: productCode }
      });
    },
    markRowAsSelected: function(row) {
      return this.$store.state.lastSelectedProduct == row.product_code
        ? "bg-green-300"
        : "";
    },
    productSortChange(params) {
      this.$store.dispatch("setProductSortOptions", params[0]);
    },
    productColumnFilter(params) {
      let param = {
        category: params.columnFilters.category,
        type: params.columnFilters.type
      };
      this.$store.dispatch("setProductFilterOptions", param);
    },
    searchProducts: async function() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setProductSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        this.searchResults = await this.ProductService.getProducts(this.searchTerm);
        this.searchTermWas = this.searchTerm;
        this.searchTerm = "";
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },
    clearSearch: function() {
      this.$store.dispatch("setProductSearchTerm", "");
      this.searchResults = [];
      this.searchTermWas = "";
      this.searchTerm = "";
      this.searchState = "Pending";
      this.$nextTick(() => {
        this.currentTab = "products";
      });
    },
    processVerifiedAt: function(checkedAt) {
      if (!checkedAt) {
        return `<svg
                  class="stroke-current w-6 h-6 text-red-400"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01" />
                </svg>`;
      }
      if (this.$moment(checkedAt).isAfter(this.twelveMonthsAgo)) {
        return `<svg
                  class="stroke-current w-6 h-6 text-green-400"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                  <path d="M22 4L12 14.01l-3-3" />
                </svg>`;
      }
      if (this.$moment(checkedAt).isAfter(this.eighteenMonthsAgo)) {
        return `<svg
                  class="stroke-current w-6 h-6 text-orange-400"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 16v-4M12 8h.01" />
                </svg>`;
      }
      if (this.$moment(checkedAt).isAfter(this.twentyFourMonthsAgo)) {
        return `<svg
                  class="stroke-current w-6 h-6 text-red-400"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01" />
                </svg>`;
      }
    }
  }
};
</script>

<style>
/* td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */
</style>